import React, { useEffect, Fragment } from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'
import Layout from '../components/Layout'
import ModalImage from 'react-modal-image'
import { StaticImage } from 'gatsby-plugin-image'
import Spacing from '../components/partials/Spacing'

import cluster_down from '../resources/images/aws_msk_vortexa/cluster-down.svg'
import debugging from '../resources/images/aws_msk_vortexa/debugging.svg'
import painful_work from '../resources/images/aws_msk_vortexa/painful-work.svg'
import architecture from '../resources/images/aws_msk_vortexa/architecture.jpg'

import maksym from '../resources/images/Homepage/testimonials/maksym.png'

import { initializeVideos } from '../helpers/initializeVideo'
import { navbarScrolling } from '../helpers/navbarScrolling'

const features = () => {
    const data = useStaticQuery(graphql`
        query AWS_MSK_Vortexa {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "AWS_MSK_Vortexa" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    useEffect(() => {
        try {
            navbarScrolling()
            initializeVideos()
        } catch (err) {
            console.log(err)
        }
        // es-lint-disable-next-line
    }, [])

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Fragment>
            {/* Banner Area */}
            {/* <section className="home-banner aws-msk-vortexa-banner">
        <span className="f-15">
          {' '}
          Get started with Lenses for AWS MSK in minutes.
          <a
            href="//portal.lenses.io/register?version=msk"
            className="ml-1 text-white mobile-padding-right-half mobile-margin-reset"
          >
            Start now &rarr;
          </a>
        </span>
      </section> */}
            <Layout linkColor={'dark'} isTransparent={true}>
                <HeaderSEO pageSEO={pageSEO} />
                <section>
                    <div className="container-1 hero-margin">
                        <div className="d-flex flex-wrap align-items-center">
                            <div className="col-md-6 mt-5">
                                <h1 className="w-75">
                                    SaaS company increases AWS MSK productivity by 200%
                                </h1>
                                <div className="pr-5 pt-3 pb-2">
                                    <p className="hero-description-dark">
                                        How Vortexa sped up and modernized their data platform with
                                        DataOps + AWS Managed Streaming for Apache Kafka.
                                    </p>
                                </div>
                                <div className="roll-button">
                                    <a
                                        className="bg-light-blue text-white"
                                        href="//portal.lenses.io/register?version=msk "
                                    >
                                        <span data-hover="Discover now">Discover now</span>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <StaticImage
                                    className="img-fluid mx-auto d-block mobile-margin-top-half"
                                    src="../resources/images/aws_msk_vortexa/hero-image.svg"
                                    placeholder="tracedSVG"
                                    alt="Kafka Governance"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <Spacing />
                <section>
                    <div className="container-1">
                        <div className="d-flex flex-wrap pt-4 align-items-center">
                            <div className="col-md-5 col-10">
                                <div className="">
                                    <p className="h2-like mobile-margin-top-half cera-pro fw-900">
                                        Vortexa tracks trillions of dollars of sea-freight moving in
                                        real-time
                                    </p>
                                    <p>
                                        Vortexa uses state-of-the-start data science and engineering
                                        to process data at a massive scale, tracking more than $7
                                        trillion per year of seaborne energy flows globally and
                                        instantly.
                                    </p>

                                    <p>
                                        The service is built around an Apache Kafka streaming data
                                        platform, where an uninterrupted flow of data and fast
                                        delivery of new data products is critical to their business.
                                    </p>
                                </div>
                            </div>
                            <div className="offset-md-1"></div>
                            <div className="col-md-6 col-12">
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="//www.vortexa.com/product "
                                >
                                    <StaticImage
                                        className="img-fluid"
                                        src="../resources/images/aws_msk_vortexa/vortexa-ship.png"
                                        placeholder="tracedSVG"
                                        alt="Vortexa"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <Spacing />

                <section>
                    <div className="container-1 mobile-margin-top">
                        <p className="text-center h4-like">Challenge</p>
                        <div className="d-flex flex-wrap justify-content-center">
                            <div className="col-lg-8 col-md-8 col-12">
                                <p className="h2-like cera-pro text-center fw-900">
                                    Kafka was powerful, but it was also a black box difficult to
                                    stabilize & self-manage{' '}
                                </p>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="d-flex flex-wrap justify-content-around">
                                <div className="col-sm-3 col-12 mobile-margin-top-half">
                                    <img
                                        className="usecase-feature"
                                        src={debugging}
                                        alt="Debugging"
                                    />
                                    <p className="mt-3 h4-like w-50 mx-auto text-center fw-700">
                                        Debugging incidents took days
                                    </p>
                                </div>
                                <div className="col-sm-3 col-12 mobile-margin-top-half">
                                    <img
                                        className="usecase-feature"
                                        src={cluster_down}
                                        alt="Cluster"
                                    />
                                    <p className="mt-3 h4-like text-center w-75 mx-auto fw-700">
                                        One small mistake could bring down an entire cluster
                                    </p>
                                </div>
                                <div className="col-sm-3 col-12 mobile-margin-top-half">
                                    <img
                                        className="usecase-feature"
                                        src={painful_work}
                                        alt="Production"
                                    />
                                    <p className="mt-3 h4-like  w-50 mx-auto text-center fw-700">
                                        Getting to production was painful
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Spacing />
                <section>
                    <div className="container-1 mobile-margin-top">
                        <p className="text-center h4-like">Solution</p>
                        <div className="d-flex flex-wrap justify-content-center">
                            <div className="col-lg-8 col-md-8 col-12">
                                <p className="h2-like cera-pro fw-900 text-center">
                                    Adding DataOps to Apache Kafka
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="container-1 ">
                        <div className="d-flex flex-wrap align-items-center justify-content-center">
                            <div className="col-md-5 col-10">
                                <p>
                                    “The combination of Lenses.io and Amazon MSK allows us to focus
                                    on business logic instead of SLAs.” - Jakub Korzeniowski, Head
                                    of Data Services at Vortexa.
                                </p>
                                <ul className="red-checked-list red-checked-list-lh-0 pl-0">
                                    <li>
                                        <b>Open monitoring:</b> Vortexa views their application
                                        topology and exposes consumer lag metrics, visualized in
                                        Lenses before forwarding to third-party tools such as Slack
                                        and PagerDuty.
                                    </li>
                                    <br />

                                    <li>
                                        <b>Building streaming apps:</b> Lenses provides full
                                        management capabilities for streaming applications in Apache
                                        Kafka clusters, making testing and ops painless and more
                                        intuitive.
                                    </li>
                                    <br />

                                    <li>
                                        <b>Self-service for Kafka & Kubernetes:</b> Vortexa uses
                                        Lenses governance controls for real-time applications that
                                        run across their MSK and Kubernetes platform.
                                    </li>
                                </ul>
                            </div>
                            <div className="offset-md-1"></div>
                            <div className="col-md-6 col-12 mobile-margin-top-half">
                                <ModalImage
                                    className="img-fluid shadow-sm"
                                    small={architecture}
                                    large={architecture}
                                    alt="Architecture"
                                />
                                <p className="text-center f-12 mt-2">
                                    Vortexa uses{' '}
                                    <a
                                        className="cyan"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="//docs.aws.amazon.com/msk/latest/developerguide/monitoring.html#open-monitoring"
                                    >
                                        {' '}
                                        open monitoring with Prometheus{' '}
                                    </a>{' '}
                                    to access JMX metrics that Amazon MSK brokers generate. See the
                                    architecture above.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <Spacing />

                <section className="footer-color">
                    <div className="container-1">
                        <div className="d-flex flex-wrap py-5 align-items-center">
                            <div className="col-md-5 col-12">
                                <p className="h4-like">Demo</p>
                                <h2 className="fw-900 w-75 ">
                                    Monitoring & DataOps for AWS MSK with Lenses.io
                                </h2>
                                <p>
                                    Watch the walk-through on how to get greater visibility and
                                    self-service governance over your AWS Apache Kafka Managed
                                    Streaming for Apache Kafka and AWS EKS data platform.
                                </p>
                                <div className="mt-4">
                                    <a
                                        className="primary-text"
                                        href="/videos/the-power-of-two-aws-msk-webinar/"
                                    >
                                        Watch our full webinar with AWS{' '}
                                        <i className="red-pointer-right"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-7 col-12">
                                <div className="cloud-aws-msk-video">
                                    <iframe
                                        src=""
                                        data-src="//player.vimeo.com/video/466080278"
                                        style={{
                                            position: 'absolute',
                                            top: '0',
                                            left: '0',
                                            width: '100%',
                                            height: '100%',
                                        }}
                                        frameBorder="0"
                                        allow="autoplay; fullscreen"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg-aws-msk-vortexa py-5">
                    <div className="container-1">
                        <div className="d-flex flex-wrap justify-content-center">
                            <div className="col-sm-6 col-12">
                                <p className="f-16 text-center cera-pro text-uppercase text-white">
                                    {' '}
                                    Results
                                </p>
                                <h2 className="fw-900 text-center text-white">
                                    Fast-tracking data to production in minutes, not days
                                </h2>
                                <div className="mx-auto mb-3 w-20">
                                    <StaticImage
                                        className="img-fluid"
                                        src="../resources/images/clients/vortexa/vortexa_white.png"
                                        placeholder="tracedSVG"
                                        alt="Vortexa"
                                    />
                                </div>

                                <p className="f-20 text-white text-center mb-0">
                                    “Amazon MSK and Lenses.io have been pivotal technologies for
                                    Vortexa, enabling us to shift significant efforts from
                                    maintaining and stabilizing a complex and fragile Kafka
                                    infrastructure to focusing more on the quality of analytics and
                                    market insights that directly impact the value we deliver to our
                                    customers.”
                                </p>
                                <div className="d-flex flex-wrap justify-content-center align-items-center">
                                    <ModalImage
                                        small={maksym}
                                        large={maksym}
                                        className="maksym-aws-msk"
                                        alt="Maksym Schipka"
                                    />
                                    <p className="f-18 fw-900 pl-3 pt-3 text-white">
                                        {' '}
                                        Maksym Schipka, CTO - Vortexa
                                    </p>
                                </div>

                                <div className="mt-4 text-center">
                                    <a className="primary-text" href="/customers/vortexa/">
                                        Read their story <i className="red-pointer-right"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mt-5">
                                    <div className="d-flex flex-wrap justify-content-around">
                                        <div className="col mx-2 mobile-margin-top-half">
                                            <p className="text-white text-center cera-pro f-48 fw-900">
                                                {' '}
                                                15%
                                            </p>
                                            <p className="text-white text-center cera-pro">
                                                <b> Working hours </b> saved on Apache Kafka
                                                management
                                            </p>
                                        </div>
                                        <div className="col mx-2 mobile-margin-top-half">
                                            <p className="text-white text-center cera-pro f-48 fw-900">
                                                {' '}
                                                Fast{' '}
                                            </p>
                                            <p className="text-white text-center cera-pro">
                                                <b> Takes minutes </b> (not days) to deploy to
                                                production
                                            </p>
                                        </div>
                                        <div className="col mx-2 mobile-margin-top-half">
                                            <p className="text-white text-center cera-pro f-48 fw-900">
                                                200%
                                            </p>
                                            <p className="text-white text-center cera-pro">
                                                <b>Increase</b> in Kafka users (both operators and
                                                application developers)
                                            </p>
                                        </div>
                                        <div className="col mx-2 mobile-margin-top-half">
                                            <p className="text-white text-center cera-pro f-48 fw-900">
                                                90%
                                            </p>
                                            <p className="text-white text-center cera-pro">
                                                <b> Reduction</b> in AIS signal noise aided by QA
                                                done exclusively in Lenses.io
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mobile-margin-bottom mobile-margin-top my-5">
                    <div className="container-1 bg-red rounded-1 p-5">
                        <div className="d-flex flex-wrap">
                            <div className="col-md-12 col-12 text-center">
                                <div className="roll-button">
                                    <p className="text-uppercase text-white cera-pro mb-0">
                                        How to
                                    </p>
                                    <p className="w-50 fw-900 f-28 mx-auto cera-pro lh-2-1 text-white mobile-w-100">
                                        Get started with Lenses for AWS MSK in minutes
                                    </p>
                                    <a
                                        className="bg-white primary-text px-5"
                                        href="//portal.lenses.io/register?version=msk"
                                    >
                                        <span data-hover="Start"> Start</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </Fragment>
    )
}

export default features
