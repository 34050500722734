export function navbarScrolling() {
    var nav = document.querySelector('.navbar-transparent')
    var navLinksColor = document.querySelectorAll('.nav-link-transparent')
    var navToggler = document.querySelector('.navbar-toggler')
    if (typeof window !== 'undefined') {
        if (window.innerWidth < 992) {
            nav.classList.add('scrolled')
            navToggler.classList.add('scrolled')
            Array.from(navLinksColor).map((navLink) => {
                navLink.classList.add('scrolled')
            })
        }
    }

    const scrolling = () => {
        var nav = document.querySelector('.navbar-transparent')
        var navLinksColor = document.querySelectorAll('.nav-link-transparent')
        var navToggler = document.querySelector('.navbar-toggler')
        if (typeof window !== 'undefined' && window.scrollY !== undefined) {
            if (window.scrollY > 0) {
                nav.classList.add('scrolled')
                navToggler.classList.add('scrolled')
                Array.from(navLinksColor).map((navLink) => {
                    navLink.classList.add('scrolled')
                })
            } else {
                if (window.innerWidth > 992) {
                    nav.classList.remove('scrolled')
                    navToggler.classList.remove('scrolled')
                    Array.from(navLinksColor).map((navLink) => {
                        navLink.classList.remove('scrolled')
                    })
                }
            }
        }
    }

    window.addEventListener('scroll', scrolling)
}
